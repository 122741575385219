<template>
  <div>
    <!--    左侧作业列表-->
    <el-aside style=" position: fixed;height: 100vh;overflow: paged-y;left: 0">
      <el-menu class="el-menu-vertical-demo" style="border-right: 0;width: 280px;">
        <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
          <el-radio-button :label="false">展开</el-radio-button>
          <el-radio-button :label="true">收起</el-radio-button>
        </el-radio-group>
        <el-menu :default-active="allTasks[0].id+''" class="el-menu-vertical-demo"
                 :collapse="isCollapse">
          <el-menu-item v-for="task in allTasks" :key="task.id" :index="task.id+''"
                        @click="checkCurrentTask(task),checkSubmission(),mutualSubmissionShow=false">
            <i class="el-icon-menu"></i>
            <span slot="title">{{ task.title }}</span>
          </el-menu-item>
        </el-menu>
      </el-menu>
    </el-aside>
    <!--    /左侧作业列表-->
    <el-container>
      <el-main>
        <!--        指示栏-->
        <el-card id="evaluation" v-show="false"></el-card>
        <div v-show="!submissionShow&&!mutualSubmissionShow&&!submitShow" class="leadCard">
          <img
            src="https://cos.mooc.huangzhaokun.top/moocImg/undraw_On_the_office_re_cxds.png"
            style="width: 300px" alt="">
          <h3>
            <b-icon icon="arrow-left-square" animation="cylon"></b-icon>
            从左侧列表选择查看
          </h3>
        </div>
        <transition name="el-zoom-in-top">
          <!--        学生互评框-->
          <el-card id="studentEvaluation" v-show="mutualSubmissionShow" v-loading="loading">
            <div slot="header" class="clearfix">
              <span>学生互评</span>
            </div>
            <p>{{ currentTask.title }}</p>
            {{ currentTask.description }}
            <el-divider content-position="left">提交时间:{{ mutualSubmission.gmtCreate }}</el-divider>
            <div class="demo-image__preview">
              <el-image :src="mutualSubmission.submissionUrl" alt="点击查看大图" style="max-height: 500px"
                        :preview-src-list="[mutualSubmission.submissionUrl]" fit="cover"></el-image>
            </div>
            <el-divider content-position="left"><span class="demonstration">学生评分:<small>(0-10)</small>
          <el-rate
            allow-half
            disabled
            :value="(mutualRate/2)"
            :colors="colors">
            </el-rate></span>
            </el-divider>
            <div style="text-align: center">
              <div>
                <el-input-number v-model="mutualRate" :min="0" :max="10" :step="0.5"></el-input-number>
                <el-button type="primary" style="margin-left: 20px" @click="submitMutualScore">提交</el-button>
              </div>
            </div>
          </el-card>
          <!--        /学生互评框-->
        </transition>
        <transition name="el-zoom-in-top">
          <!--        显示作业框-->
          <el-card v-show="submissionShow" id="submission">
            <div slot="header" class="clearfix">
              <span>{{ currentTask.title }}</span> <small>已提交</small>
              <span v-if="submission.score!==null" style="float: right;"><b-icon icon="check" animation="throb"
                                                                                 font-scale="2"></b-icon>Final&nbsp;Score:{{
                  submission.score
                }}</span>
              <el-button v-else style="float: right; padding: 3px 0" type="text" @click="dialogVisible = true">重新提交
              </el-button>
            </div>
            <p><small>提交时间: {{ submission.gmtCreate }}</small></p>
            <p>{{ currentTask.description }}</p>
            <img :src="submission.submissionUrl" alt="" class="submissionPic">
          </el-card>
          <!--        /显示作业框-->
        </transition>

        <transition name="el-zoom-in-top">
          <!--        提交作业框-->
          <el-card class="homework" v-show="submitShow">
            <p>{{ currentTask.title }}</p>
            <p>{{ currentTask.description }}</p>
            <el-divider></el-divider>
            <div style="text-align: center">
              <el-button type="primary" @click="dialogVisible = true">上传作业</el-button>
            </div>
          </el-card>
          <!--        /提交作业框-->
        </transition>

      </el-main>
    </el-container>
    <!--    弹框-->
    <el-dialog
      title="作业上传"
      :visible.sync="dialogVisible"
      width="40%">
      <el-card shadow="never" style="padding: 10px 0">
        <input type="file" class="fileExport" @change="handleFileChange" ref="inputer1">
        <el-row>
          <el-button style="float:right; margin-left: 5px" @click="reload" round>刷新</el-button>
          <el-button id="submitButton" style="float: right" @click="putObj()" round>提交</el-button>
        </el-row>
        <el-progress id="uploadPercentage" v-show="loadingPercentage!==0" :percentage="loadingPercentage"
                     style="bottom: -20px;position: relative;width: 0" :show-text="false"></el-progress>
      </el-card>
      <ScanTempFiles ref="scanTempFiles" v-if="isComponentAlive"
                     :if-submitted="submissionShow"
                     :update-task-id="currentTask.id"
                     @recheck="checkSubmission"
      ></ScanTempFiles>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>
    <!--    /弹框-->
    <el-dialog
      title="提示"
      :visible.sync="mutualRandom"
      width="30%"
    >
      <span>评价成功,是否继续互评?</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="mutualRandom = false">取 消</el-button>
    <el-button type="primary" @click="getMutual(currentTask.id)">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import cos from '@/main'
import ScanTempFiles from '@/components/ScanTempFiles'

export default {
  name: 'MutualEvaluation',
  components: { ScanTempFiles },
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isComponentAlive: true,
      isCollapse: false,
      termId: 0,
      allTasks: [],
      currentTask: {},
      fileList: [],
      taskFile: File,
      submitShow: false,
      submission: {},
      submissionShow: false,
      studentId: 0,
      mutualSubmission: {},
      loading: false,
      mutualSubmissionShow: false,
      mutualRate: 0,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      dialogVisible: false,
      mutualRandom: false,
      loadingPercentage: 100
    }
  },
  mounted () {
    // 在mounted生命周期函数中实例化echarts对象。因为要确保dom元素已经挂载到页面中
    this.myPie()
  },
  created () {
    this.termId = this.$route.params.termId
    this.getAllTasksByTermId()
    this.getStudentId().then(response => {
      this.studentId = response.data.data.id
      this.showTerm(this.termId)
    })
  },
  watch: {
    currentTask: {
      deep: true,
      handler (val, old) {
        if (val.status === 'mutual') {
          this.$confirm('当前任务互评已开启,是否参与互评?')
            .then(_ => {
              this.getMutual(val.id)
            })
            .catch(_ => {
            })
        } else {
          this.mutualSubmissionShow = false
        }
      }
    }
  },
  methods: {
    myPie () {
      // 基于准备好的dom，初始化echarts实例
      const evaluation = this.$echarts.init(document.getElementById('evaluation'))
      const data = [
        {
          name: '提交作业',
          value: 100
        },
        {
          name: '自评作业',
          value: 0
        },
        {
          name: '互评作业',
          value: 35
        },
        {
          name: '成绩公布',
          value: 30
        }
      ]
      const titleArr = []
      const seriesArr = []
      const colors = [
        ['#389af4', '#dfeaff'],
        ['#ff8c37', '#ffdcc3'],
        ['#fd6f97', '#fed4e0'],
        ['#a181fc', '#e3d9fe']
      ]
      data.forEach(function (item, index) {
        titleArr.push({
          text: item.name,
          left: index * 20 + 20 + '%',
          top: '70%',
          textAlign: 'center',
          textStyle: {
            fontWeight: 'normal',
            fontSize: '16',
            color: colors[index][0],
            textAlign: 'center'
          }
        })
        seriesArr.push({
          name: item.name,
          type: 'pie',
          clockWise: false,
          radius: [60, 70],
          itemStyle: {
            normal: {
              color: colors[index][0],
              shadowColor: colors[index][0],
              shadowBlur: 0,
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          center: [index * 20 + 20 + '%', '40%'],
          data: [
            {
              value: item.value,
              label: {
                normal: {
                  formatter: function (params) {
                    return params.value + '%'
                  },
                  position: 'center',
                  show: true,
                  textStyle: {
                    fontSize: '20',
                    fontWeight: 'bold',
                    color: colors[index][0]
                  }
                }
              }
            },
            {
              value: 100 - item.value,
              name: 'invisible',
              itemStyle: {
                normal: {
                  color: colors[index][1]
                },
                emphasis: {
                  color: colors[index][1]
                }
              }
            }
          ]
        })
      })
      const option = {
        backgroundColor: '#fff',
        title: titleArr,
        series: seriesArr
      }
      evaluation.setOption(option)// 使用刚指定的配置项和数据显示图表
      window.addEventListener('resize', function () {
        evaluation.resize()
      })
    },
    getAllTasksByTermId () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/task/showTasksByTermId',
        params: {
          termId: this.termId,
          currentPage: 1,
          pageSize: 100
        }
      }).then(res => {
        if (res.status) {
          this.allTasks = res.data.data.content
          console.log('allTasks:', this.allTasks)
        }
      })
    },
    checkCurrentTask (item) {
      this.currentTask = item
    },
    uploadHttpRequest (data) {
      this.fileList.push(data)
    },
    handleFileChange (e) {
      const inputDOM = this.$refs.inputer1
      this.file = inputDOM.files[0]
      this.formData = new FormData()
      this.formData.append('file', this.file)
      this.formData.append('taskId', this.currentTask.id)
    },
    checkSubmission () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/showMyTaskSubmissionByTaskId',
        params: {
          taskId: this.currentTask.id
        }
      }).then(res => {
        if (res.status) {
          console.log('checkSubmission:', res)
          if (res.data.data !== null) {
            this.submissionShow = true
            this.submitShow = false
            this.submission = res.data.data
          } else {
            this.submissionShow = false
            this.submitShow = true
          }
        }
      })
    },
    getStudentId () {
      return axios({
        method: 'GET',
        url: 'https://mooc.kirinnorine.top/student/checkLogin'
      })
    },
    getMutual (taskId) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/random',
        params: {
          taskId: taskId
        }
      }).then(res => {
        if (res.status) {
          if (res.data.status) {
            console.log('mutualSubmission:', res)
            this.submitShow = false
            this.mutualSubmissionShow = true
            this.mutualSubmission = res.data.data
            this.mutualRandom = false
          } else {
            this.mutualSubmissionShow = false
            this.mutualRandom = false
            this.$message(res.data.message)
          }
        }
      })
    },
    submitMutualScore () {
      this.loading = true
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskMutualAssessment/mutualScore',
        params: {
          taskSubmissionId: this.mutualSubmission.id,
          score: this.mutualRate
        }
      }).then(res => {
        this.loading = false
        if (res.status) {
          if (res.data.status) {
            this.$message.success('评价成功')
            this.mutualRandom = true
          } else {
            this.$message.error(res.data.message)
          }
        }
      })
    },
    putObj () {
      const date = new Date()
      const hour = date.getHours()
      let bucket
      if (hour < 8) {
        bucket = 'a'
      } else if (hour < 16) {
        bucket = 'b'
      } else {
        bucket = 'c'
      }
      const fileName = this.file.name
      const keyID = 'student/' + this.studentId + '/' + fileName
      // student/100001/abc.txt
      cos.putObject({
        Bucket: 'mooc-temp-' + bucket + '-1303830952',
        Region: 'ap-shanghai',
        Key: keyID,
        StorageClass: 'STANDARD',
        Body: this.file, // 上传文件对象
        onProgress: function (progressData) {
          console.log(JSON.stringify(progressData))
          const percentage = document.getElementById('uploadPercentage')
          const submitButton = document.getElementById('submitButton')
          percentage.style.width = progressData.percent * 100 + '%'
          if (progressData.percent === 1) {
            percentage.style.width = '0%'
            alert('临时文件上传成功，请查看并上传作业！')
            submitButton.disabled = false
            this.reload()
          } else {
            submitButton.disabled = true
          }
        }
      }, function (err, data) {
        console.log(err || data)
        if (data.statusCode === 200) {
          const location = data.Location
          console.log(location)
          console.log('bucket:' + bucket + 'keyID:' + keyID)
          axios({
            method: 'POST',
            url: 'https://mooc.kirinnorine.top/tempFile/checkUploadedFile',
            params: {
              bucket: bucket.toUpperCase(),
              path: keyID
            }
          }).then(res => {
            if (res.status) {
              console.log(res.data)
              this.$message.success(res.data.message)
            }
          })
        }
      })
    },
    reload () {
      this.isComponentAlive = false
      this.$nextTick(function () {
        this.isComponentAlive = true
      })
    }
  }
}
</script>

<style scoped>

#studentEvaluation {
  width: 80%;
  margin: 10px auto;
}

#evaluation {
  width: 80%;
  height: 300px;
  margin: 10px auto;
}

.homework {
  width: 80%;
  margin: 10px auto;
  padding-bottom: 20px;
}

#submission {
  width: 80%;
  margin: 10px auto;
  padding-bottom: 20px;
}

/* 设置流光 */
@keyframes sun {
  100% {
    background-position: -400% 0;
  }
}

.submissionPic {
  width: 60%;
}

.leadCard {
  margin: 100px;
  text-align: center;
}

.fileExport {
  border: 0;
}
</style>
