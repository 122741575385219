<template>
  <div class="filesForm">
    <el-table
      :data="tempFiles"
      stripe
      style="width: 100%">
      <el-table-column
        prop="file"
        label="文件名">
      </el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="100">
        <template slot-scope="scope">
          {{ UpdateTaskId }}
          <a :href="'https://'+scope.row.url" style="text-decoration: none" target="_blank">预览文件</a>
          <el-button v-if="ifSubmitted" @click="resubmitForTask(scope.row.path,scope.row.bucket)">重新上传</el-button>
          <el-button v-else @click="updateForTask(scope.row.path,scope.row.bucket)">上传</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ScanTempFiles',
  data () {
    return {
      tempFilesRoot: [],
      tempFiles: []
    }
  },
  props: {
    updateTaskId: Number,
    ifSubmitted: Boolean
  },
  created () {
    this.getAllTempFilesRoot()
  },
  methods: {
    getAllTempFilesRoot () {
      axios({
        method: 'GET',
        url: 'https://mooc.kirinnorine.top/tempFile/showMyTempFiles'
      }).then(res => {
        if (res.status) {
          console.log('res', res.data)
          this.tempFilesRoot = res.data.data
          this.getAllFiles()
        }
      })
    },
    getAllFiles () {
      this.tempFilesRoot.forEach(file => {
        const bucket = file.substring(9, 10)
        file = file.substring(11)
        file = file.substring(file.indexOf('.') + 1)
        file = file.substring(file.indexOf('.') + 1)
        const path = file
        const url = 'mooc-temp-' + bucket.toLowerCase() + '-1303830952.cos.ap-shanghai.myqcloud.com/' + file
        file = file.substring(file.indexOf('/') + 1)
        file = file.substring(file.indexOf('/') + 1)
        this.tempFiles.push({
          file: file,
          url: url,
          path: path,
          bucket: bucket.toUpperCase()
        })
      })
    },
    updateForTask (path, bucket) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/submitForTask',
        params: {
          taskId: this.updateTaskId,
          bucket: bucket,
          path: path
        }
      }).then(res => {
        if (res.status) {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.$emit('recheck')
          } else {
            this.$message.error(res.data.message)
          }
        }
      })
    },
    resubmitForTask (path, bucket) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/taskSubmission/resubmit',
        params: {
          taskId: this.updateTaskId,
          bucket: bucket,
          path: path
        }
      }).then(res => {
        if (res.status) {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.$emit('recheck')
          } else {
            this.$message.error(res.data.message)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.filesForm {
  margin: 0;
}
</style>
